module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.23.1_xfeie5kqc3cxni2hjtrzzpeh2e/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.23.1_7f3fwn2ustopsbyhi4pocfkrna/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Julian Marmier – Portfolio","short_name":"Julian Marmier","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"content/assets/julianm_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a62ab85281063969b06240a211d66be"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@6.23.0_oyuizfetyksaivyahnndwt7sfe/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.23.1_mvj35x27v33ajtq4bavo6x5xpe/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.23.1_irqce3nurqbq3q6xaoy5cpkhna/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
